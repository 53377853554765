import type { ItemRecordCore } from "../entities/item.js";

interface PriceEntry {
  unit_price: number;
  unit: "item" | "sqft" | "sqin" | "m2";
}

function itemFreightProperties(item: ItemRecordCore, priceEntry: PriceEntry | null) {
  const width = item.cache?.width_in ?? 0;
  const height = item.cache?.height_in ?? 0;
  const weight = item.cache?.weight ?? 0;
  let price = 0;
  if (priceEntry) {
    const area = (width / 12) * (height / 12);

    if (priceEntry.unit === "item") {
      price = priceEntry.unit_price;
    } else if (priceEntry.unit === "sqft") {
      price = priceEntry.unit_price * area;
    } else if (priceEntry.unit === "sqin") {
      price = priceEntry.unit_price * area * 144;
    } else if (priceEntry.unit === "m2") {
      price = priceEntry.unit_price * area * 0.092903;
    }
    price = Math.round(price * 100);
  }

  return { id: item.id, width, height, weight, price };
}

export { itemFreightProperties };
