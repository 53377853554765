import { defineItemScript } from "@local/lamina-script";
import { DimText } from "dimtext";

const dt = new DimText();

function parseItemScript(script) {
  const body = script
    .replace(/^\s*export\s+default\s+.*?;?\s*$/gm, "")
    .replace(/^\s*import\s.*?;?\s*$/gm, "")
    .replace(/^\s*export\s.*?;?\s*$/gm, "");

  try {
    return new Function(
      "defineItemScript",
      `
			"use strict";
			${body}
			return script;
		`,
    )(defineItemScript);
  } catch (e) {
    console.error(e);
    return null;
  }
}

function parseItemParams(input) {
  return Object.keys(input)
    .sort((a, b) => {
      const ao = input[a].order || 0;
      const bo = input[b].order || 0;
      return ao - bo;
    })
    .map((key) => {
      const d = input[key];
      let value = d.default;
      let type = d.type;
      let options = [];
      if (d.type === "dimtext" && typeof d.default === "string") {
        value = dt.parse(d.default).value;
      }

      if (d.constraint?.type === "choice") {
        type = "select";
        options = d.constraint.values.map((v) => {
          let oval = v.value;
          if (d.type === "dimtext" && typeof oval === "string") {
            oval = dt.parse(oval).value;
          }

          return {
            label: v.name,
            value: oval,
          };
        });
      }

      let label = d.label || key;
      let min;
      let max;
      if (d.constraint?.type === "range") {
        if (d.constraint.min) {
          min = dt.parse(d.constraint.min).value;
        }

        if (d.constraint.max) {
          max = dt.parse(d.constraint.max).value;
        }

        if (d.constraint.min && d.constraint.max) {
          label += ` (${min} - ${max})`;
        } else if (d.constraint.min) {
          label += ` (≥ ${min})`;
        } else if (d.constraint.max) {
          label += ` (≤ ${max})`;
        }
      }

      return {
        key,
        type,
        label,
        options,
        value,
        min,
        max,
      };
    });
}

export { parseItemScript, parseItemParams };
