<svelte:options strictprops={false} />

<script>
  import upperCase from "lodash/upperCase";
  import upperFirst from "lodash/upperFirst";
  import { navigate } from "svelte-routing";

  import EditableHeader from "./sidebar/EditableHeader.svelte";
  import NavButton from "./toolbar/NavButton.svelte";

  import LaminaIcon from "src/assets/icons/lamina.svg";
  import ShapeExchangeText from "src/assets/icons/shape-exchange-text.svg";
  // import HomeIcon from "src/assets/icons/home-filled.svg";
  import LeftArrowIcon from "src/assets/icons/arrow-left.svg";
  import GearIcon from "src/assets/icons/gear.svg";
  import GroupIcon from "src/assets/icons/group.svg";
  import ProductsIcon from "src/assets/icons/products.svg";
  import VideoIcon from "src/assets/icons/video.svg";
  import ExitIcon from "src/assets/icons/exit.svg";
  import CaretDownIcon from "src/assets/icons/caret-down.svg";

  import { clickOutside } from "svelte-utilities";
  import { user, profile } from "src/stores/auth.js";
  import { navHistory, pathRoot } from "src/stores/ui.js";
  import getUserInitials from "@local/extensions/formatters/get-user-initials.js";

  export let context = "home";
  export let group = null;
  export let tab = null;
  export let disabled = false;

  const colors = {
    "#6b7280": "#334155",
    "#ef4444": "#b91c1c",
    "#f97316": "#c2410c",
    "#f59e0b": "#b45309",
    "#eab308": "#a16207",
    "#84cc16": "#4d7c0f",
    "#22c55e": "#15803d",
    "#10b981": "#047857",
    "#14b8a6": "#0f766e",
    "#06b6d4": "#0e7490",
    "#0ea5e9": "#0369a1",
    "#3b82f6": "#1d4ed8",
    "#6366f1": "#4338ca",
    "#8b5cf6": "#6d28d9",
    "#a855f7": "#7e22ce",
    "#d946ef": "#a21caf",
    "#ec4899": "#be185d",
    "#f43f5e": "#be123c",
  };

  let userDropdownButton;
  let showTabDropdown = false;
  let showUserDropdown = false;
  let tabDropdownButton;

  $: userInitials = upperCase(getUserInitials($profile));
  $: home = context === "home";

  async function logout() {
    showUserDropdown = false;
    navigate("/logout");
  }

  function toggleUserDropdown() {
    showUserDropdown = !showUserDropdown;
  }

  function hideUserDropdown() {
    showUserDropdown = false;
  }

  function navPath(pr, tab, slug, j) {
    const base = `${pr}${tab}`;
    if (slug && j) {
      const record = j[slug];
      if (record) {
        return `${base}/${slug}`;
      } else {
        return base;
      }
    }
    return base;
  }

  function updateJob(prop, value) {
    group.updateJobProp(prop, value);
  }

  function clickTab(path) {
    showTabDropdown = !showTabDropdown;
    navigate(path);
  }
</script>

<nav class="navbar flex-none bg-black text-gray-400">
  <div class="px-4">
    <div class="flex items-center justify-between h-12">
      <div class="flex items-center gap-2 h-full">
        <button class="logo-container" class:editor={!home} on:click={() => navigate("/")}>
          {#if home}
            <div>
              <LaminaIcon />
            </div>
          {:else}
            <div class="text-white">
              <LeftArrowIcon />
            </div>
          {/if}
        </button>
        <button class="cursor-pointer pr-2" on:click={() => navigate("/")}>
          <ShapeExchangeText />
        </button>
        <div class="flex items-center">
          {#if context === "products"}
            <div class="text-white font-bold text-lg">Products</div>
          {:else if context === "group"}
            <div class="text-white font-bold text-lg">
              <EditableHeader
                inheritsize
                value={$group?.job.name}
                {disabled}
                on:change={(e) => updateJob("name", e.detail.value)} />
            </div>
          {/if}
        </div>

        {#if $group}
          <div class="h-full text-sm hidden md:flex">
            <NavButton
              active={tab === "items"}
              path={navPath($pathRoot, "items", $navHistory.items, $group.items)}>
              Items
            </NavButton>
            {#if $group?.project_type !== "product"}
              <NavButton
                active={tab === "types"}
                path={navPath($pathRoot, "types", $navHistory.types, $group.types)}>
                Types
              </NavButton>
              <NavButton
                active={tab === "fabrications"}
                path={navPath($pathRoot, "fabrications", $navHistory.fabrications, $group.data.fabrications)}>
                Fabrications
              </NavButton>
              <NavButton
                active={tab === "openings"}
                path={navPath($pathRoot, "openings", $navHistory.collections, $group.items)}>
                Openings
              </NavButton>
              <NavButton
                active={tab === "documents"}
                path={navPath($pathRoot, "documents", $navHistory.documents, $group.data.documents)}>
                Documents
              </NavButton>
            {/if}
          </div>

          <div
            class="md:hidden text-white text-xs relative"
            use:clickOutside={[tabDropdownButton]}
            on:outclick={() => (showTabDropdown = false)}>
            <button
              bind:this={tabDropdownButton}
              class="flex gap-1 items-center"
              on:click={() => (showTabDropdown = !showTabDropdown)}>
              <div>
                {upperFirst(tab)}
              </div>
              <span class="opacity-50">
                <CaretDownIcon />
              </span>
            </button>
            {#if showTabDropdown}
              <div class="dropdown-container left-0 top-0 mt-6">
                <button
                  class="dropdown-button-item"
                  on:click={() => clickTab(navPath($pathRoot, "items", $navHistory.items, $group.items))}>
                  Items
                </button>
                {#if $group?.project_type !== "product"}
                  <button
                    class="dropdown-button-item"
                    on:click={() => clickTab(navPath($pathRoot, "types", $navHistory.types, $group.types))}>
                    Types
                  </button>
                  <button
                    class="dropdown-button-item"
                    on:click={() =>
                      clickTab(
                        navPath(
                          $pathRoot,
                          "fabrications",
                          $navHistory.fabrications,
                          $group.data.fabrications,
                        ),
                      )}>
                    Fabrications
                  </button>
                  <button
                    class="dropdown-button-item"
                    on:click={() =>
                      clickTab(navPath($pathRoot, "openings", $navHistory.collections, $group.items))}>
                    Openings
                  </button>
                  <button
                    class="dropdown-button-item"
                    on:click={() =>
                      clickTab(
                        navPath($pathRoot, "documents", $navHistory.documents, $group.data.documents),
                      )}>
                    Documents
                  </button>
                {/if}
              </div>
            {/if}
          </div>
        {:else if context === "products"}
          <div class="h-full text-sm hidden md:flex">
            {#if $profile?.organization?.org_type === "supplier"}
              <NavButton active={tab === "makeups"} path="/products/makeups">Makeups</NavButton>
              <NavButton active={tab === "materials"} path="/products/materials">Materials</NavButton>
              <NavButton active={tab === "surfaces"} path="/products/surfaces">Surfaces</NavButton>
              <NavButton active={tab === "edges"} path="/products/edges">Edges</NavButton>
              <NavButton active={tab === "fabrications"} path="/products/fabrications">Fabs</NavButton>
              {#if $profile?.user_role === "developer"}
                <NavButton active={tab === "items"} path="/products/items">Items</NavButton>
              {/if}
            {/if}
            <!-- <NavButton active={tab === "types"} path="/products/types">Types</NavButton> -->
          </div>

          <div
            class="md:hidden text-white text-xs relative"
            use:clickOutside={[tabDropdownButton]}
            on:outclick={() => (showTabDropdown = false)}>
            <button
              bind:this={tabDropdownButton}
              class="flex gap-1 items-center"
              on:click={() => (showTabDropdown = !showTabDropdown)}>
              <div>
                {upperFirst(tab)}
              </div>
              <span class="opacity-50">
                <CaretDownIcon />
              </span>
            </button>
            {#if showTabDropdown}
              <div class="dropdown-container left-0 top-0 mt-6">
                {#if $profile?.organization?.org_type === "supplier"}
                  <button class="dropdown-button-item" on:click={() => clickTab("/products/makeups")}>
                    Makeups
                  </button>
                  <button class="dropdown-button-item" on:click={() => clickTab("/products/materials")}>
                    Materials
                  </button>
                  <button class="dropdown-button-item" on:click={() => clickTab("/products/surfaces")}>
                    Surfaces
                  </button>
                  <button class="dropdown-button-item" on:click={() => clickTab("/products/edges")}>
                    Edges
                  </button>
                  <button class="dropdown-button-item" on:click={() => clickTab("/products/fabrications")}>
                    Fabrications
                  </button>
                  {#if $profile?.user_role === "developer"}
                    <button class="dropdown-button-item" on:click={() => clickTab("/products/items")}>
                      Items
                    </button>
                  {/if}
                {/if}
                <!-- <button class="dropdown-button-item" on:click={() => clickTab("/products/types")}>
                  Types
                </button> -->
              </div>
            {/if}
          </div>
        {/if}
      </div>

      <div>
        <div class="ml-4 flex gap-2 items-center md:ml-6">
          <div class="relative items-center">
            <button
              class="gap-4 items-center flex"
              bind:this={userDropdownButton}
              on:click|stopPropagation={toggleUserDropdown}>
              <div class="text-md font-bold hidden md:block">
                {$profile?.organization?.name}
              </div>
              <button
                class="profile-circle-lg relative hello-world"
                style="
                  background-color: {colors[$profile?.user_color] || '#1d4ed8'};
                  border-color: {$profile?.user_color || '#3b82f6'};
                ">
                {userInitials}
              </button>
            </button>
            {#if showUserDropdown}
              <div
                class="absolute w-52 mt-2 right-0 z-40 bg-white border rounded text-gray-800 drop-shadow-lg text-sm"
                use:clickOutside={[userDropdownButton]}
                on:outclick={hideUserDropdown}>
                <div class="border-b py-2">
                  <div class="dropdown-item py-1 font-bold">
                    {($profile && $profile.username) || $user.email}
                  </div>
                  <div class="dropdown-item py-1 text-xs text-gray-500">
                    {$user.email}
                  </div>
                  <div class="dropdown-item py-1 text-xs text-gray-500 md:hidden">
                    {$profile?.organization?.name}
                  </div>
                </div>
                <div class="p-2 text-xs">
                  <button class="dropdown-button-item" on:click={() => navigate(`/settings`)}>
                    <GearIcon />
                    <div>Settings</div>
                  </button>
                  {#if $profile?.organization?.org_type === "supplier"}
                    <button class="dropdown-button-item" on:click={() => navigate("/products")}>
                      <ProductsIcon />
                      <div>Products</div>
                    </button>
                  {/if}
                  <button class="dropdown-button-item" on:click={() => navigate("/help")}>
                    <VideoIcon />
                    <div>Help Videos</div>
                  </button>
                  {#if $profile && $profile.user_role === "developer"}
                    <button class="dropdown-button-item" on:click={() => navigate("/orgs")}>
                      <GroupIcon />
                      <div>Organizations</div>
                    </button>
                  {/if}
                  <button on:click={logout} class="dropdown-button-item">
                    <ExitIcon />
                    <div>Sign out</div>
                  </button>
                </div>
              </div>
            {/if}
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<style lang="scss">
  .logo-container {
    @apply flex-shrink-0 cursor-pointer w-6;
  }

  .navbar :global(a) {
    @apply text-white;
  }

  .navbar :global(.current) {
    @apply bg-gray-900;
  }
  .dropdown-item {
    @apply px-4 w-full text-left truncate;
  }

  .profile-circle-lg {
    @apply text-white h-8 w-8 text-center rounded-full text-sm border;
  }
</style>
